body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #131422;
}

.w-48 {
  width: 48%;
}

.ml-auto {
  margin-left: auto;
}

.mt-05 {
  margin-top: 1px;
}

.pointer:hover {
  cursor: pointer;
}

.link {
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}

.white-text {
  color: white;
}

.white-link {
  stroke: #bdbdbd !important;
  stroke-width: 2px;
}

.header-container {
  border-bottom: 3px solid white;
  padding: 8px 0px 8px 0px;
  position: relative;
}

.header-icon {
  display: flex;
  font-size: 26px;
  position: absolute;
}

.header-question-icon {
  left: 0px;
  top: 11px;
}

.header-stats-icon {
  right: 50px;
  top: 11px;
}

.header-settings-icon {
  right: 6px;
  top: 11px;
}

.guess-section {
  width: 400px !important;
}

.btn-blue {
  color: white;
  background-color: #373961;
}

.btn-blue:hover {
  color: white;
}

.guessed-container {
  width: 100%;
}

.guessed-box {
  height: 38px;
  margin-bottom: 4px;
  border-radius: 20px;
}

.wrong-guess {
  background-color: #963f46;
}

.right-guess {
  background-color: #c1994e;
}

.top-right-guess {
  background-color: #198754;
}

.guessed-text {
  padding: 6px 16px 6px 16px;
}

.guessed-image {
  border: 1px solid #bdbdbd;
}
